<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: '#00FF00',
  },
})
</script>

<template>
  <svg viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1995 0.0234375H16.9596L10.9295 6.80084L18.0234 16.0234H12.469L8.11854 10.43L3.14067 16.0234H0.378877L6.82861 8.77424L0.0234375 0.0234375H5.71889L9.65134 5.13606L14.1995 0.0234375ZM13.2307 14.3988H14.7602L4.88785 1.56272H3.24664L13.2307 14.3988Z"
      :fill="props.fill"
    />
  </svg>
</template>
