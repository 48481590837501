<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: '#00FF00',
  },
})
</script>

<template>
  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0234 10.0845C20.0234 4.52795 15.5463 0.0234375 10.0234 0.0234375C4.50059 0.0234375 0.0234375 4.52795 0.0234375 10.0845C0.0234375 15.1062 3.68028 19.2686 8.46094 20.0234V12.9928H5.92188V10.0845H8.46094V7.86796C8.46094 5.3464 9.95394 3.95356 12.238 3.95356C13.3322 3.95356 14.4765 4.15007 14.4765 4.15007V6.62605H13.2156C11.9734 6.62605 11.5859 7.40166 11.5859 8.19734V10.0845H14.3593L13.916 12.9928H11.5859V20.0234C16.3666 19.2686 20.0234 15.1064 20.0234 10.0845Z"
      :fill="props.fill"
    />
  </svg>
</template>
