<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: '#00FF00',
  },
})
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 21.56">
    <title>YouTube logo</title>
    <g>
      <path
        d="m14,0C.24,0,0,1.22,0,10.78s.24,10.78,14,10.78,14-1.22,14-10.78S27.76,0,14,0Zm4.49,11.25l-6.29,2.93c-.55.25-1-.03-1-.64v-5.53c0-.61.45-.89,1-.64l6.29,2.93c.55.26.55.68,0,.94Z"
        :fill="props.fill"
      />
    </g>
  </svg>
</template>
