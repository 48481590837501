<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: '#00FF00',
  },
})
</script>

<template>
  <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.52344 0.0234375C0.695008 0.0234375 0.0234375 0.695008 0.0234375 1.52344V16.5234C0.0234375 17.3518 0.695008 18.0234 1.52344 18.0234H16.5234C17.3518 18.0234 18.0234 17.3518 18.0234 16.5234V1.52344C18.0234 0.695008 17.3518 0.0234375 16.5234 0.0234375H1.52344ZM5.5442 4.02616C5.54983 4.98241 4.83405 5.57163 3.98467 5.56741C3.18451 5.56319 2.48701 4.92616 2.49123 4.02757C2.49545 3.18241 3.16342 2.50319 4.03108 2.52288C4.91139 2.54257 5.54983 3.18804 5.5442 4.02616ZM9.30314 6.7852H6.78315H6.78174V15.345H9.44514V15.1453C9.44514 14.7654 9.44484 14.3854 9.44454 14.0053C9.44374 12.9915 9.44284 11.9766 9.44804 10.9631C9.44944 10.717 9.46064 10.4611 9.52394 10.2262C9.76154 9.34874 10.5505 8.78204 11.4308 8.92134C11.9961 9.00984 12.3701 9.33754 12.5276 9.87054C12.6247 10.2037 12.6683 10.5623 12.6725 10.9097C12.6839 11.9573 12.6823 13.0049 12.6807 14.0526C12.6801 14.4224 12.6795 14.7924 12.6795 15.1622V15.3436H15.3514V15.1383C15.3514 14.6863 15.3512 14.2344 15.3509 13.7825C15.3504 12.653 15.3498 11.5235 15.3528 10.3936C15.3542 9.88314 15.2994 9.37974 15.1742 8.88614C14.9872 8.15204 14.6005 7.54454 13.9719 7.10584C13.5261 6.79363 13.0367 6.59254 12.4897 6.57004C12.4274 6.56745 12.3646 6.56406 12.3015 6.56065C12.0218 6.54553 11.7375 6.53017 11.4701 6.5841C10.7051 6.73738 10.033 7.08754 9.52534 7.70484C9.46634 7.77564 9.40864 7.84754 9.32254 7.95484L9.30314 7.97914V6.7852ZM2.70508 15.3478H5.35586V6.79077H2.70508V15.3478Z"
      :fill="props.fill"
    />
  </svg>
</template>
