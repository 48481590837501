<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: '#00FF00',
  },
})
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.87 25.87">
    <title>Instagram logo</title>
    <g>
      <path
        d="m17.07,12.93c0,.82-.24,1.62-.7,2.3-.46.68-1.1,1.21-1.86,1.52-.76.31-1.59.4-2.39.24s-1.54-.55-2.12-1.13c-.58-.58-.97-1.32-1.13-2.12s-.08-1.64.24-2.39c.31-.76.84-1.4,1.52-1.86.68-.45,1.48-.7,2.3-.7,1.1,0,2.15.44,2.92,1.22.78.78,1.21,1.83,1.22,2.92Zm8.8-5.69v11.38c0,1.92-.76,3.76-2.12,5.12-1.36,1.36-3.2,2.12-5.12,2.12H7.24c-1.92,0-3.76-.76-5.12-2.12-1.36-1.36-2.12-3.2-2.12-5.12V7.24c0-1.92.76-3.76,2.12-5.12C3.48.76,5.32,0,7.24,0h11.38c1.92,0,3.76.76,5.12,2.12,1.36,1.36,2.12,3.2,2.12,5.12Zm-6.73,5.69c0-1.23-.36-2.43-1.05-3.45-.68-1.02-1.65-1.82-2.79-2.29-1.13-.47-2.38-.59-3.59-.35-1.2.24-2.31.83-3.18,1.7-.87.87-1.46,1.97-1.7,3.18-.24,1.2-.12,2.45.35,3.59.47,1.13,1.27,2.1,2.29,2.79,1.02.68,2.22,1.05,3.45,1.05,1.65,0,3.23-.65,4.39-1.82,1.16-1.16,1.82-2.74,1.82-4.39Zm2.07-6.73c0-.31-.09-.61-.26-.86-.17-.26-.41-.45-.7-.57-.28-.12-.6-.15-.9-.09-.3.06-.58.21-.79.42-.22.22-.37.49-.43.79-.06.3-.03.61.09.9.12.28.32.53.57.7.25.17.55.26.86.26.41,0,.81-.16,1.1-.45.29-.29.45-.69.45-1.1Z"
        :fill="props.fill"
      />
    </g>
  </svg>
</template>
